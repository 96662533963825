<template>
  <div>
    <detect-dialog ref="liveDetectDialog" :fcltData="fcltData"> </detect-dialog>
    <div style="margin-bottom: 8px;">
      <el-button @click="$refs.liveDetectDialog.showDialog()">
        <i class="fas fa-play"></i>&nbsp;실시간 단속 상태
      </el-button>
    </div>
    <div class="dk-card-dark">
      <div class="dk-card-title">
        주간 단속량
      </div>
    </div>
    <div class="dk-card">
      <bar-chart
        :chart-data="datacollection"
        :height="340"
        :options="chartOption"
      ></bar-chart>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import detectdialog from './FCLTTYPE00001_1.vue';
import barChart from '@/components/chart/barChart.js';
export default {
  name: 'dk-ipes',
  label: '불법주정차',
  components: {
    'detect-dialog': detectdialog,
    barChart,
  },
  created() {
    this.getDetectData();
  },
  mounted() {
    console.log(this.$refs['detectDataTable']);
  },
  data() {
    return {
      chartColors: [
        '#00897B',
        '#FFB300',
        '#3949AB',
        '#F44336',
        '#E91E63',
        '#2196F3',
        '#00BCD4',
        '#4CAF50',
        '#8BC34A',
        '#FF9800',
        '#795548',
        '#9E9E9E',
        '#607D8B',
        '#D32F2F',
        '#616161',
        '#E64A19',
      ],
      liveDetectDialog: false,
      detectData: [],

      datacollection: null,
      chartOption: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      chartDarkOption: {
        responsive: true,
        maintainAspectRatio: false,
        fill: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          },
        },
        legend: {
          labels: {
            fontColor: '#ececec',
            fontSize: 12,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: '#e0e0e0',
                fontSize: 12,
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: '#e0e0e0',
                fontSize: 12,
                beginAtZero: true,
              },
              min: 0,
            },
          ],
        },
      },
    };
  },
  props: {
    fcltData: {
      required: true,
    },
  },
  methods: {
    getDetectData() {
      let param = {
        cameraId: this.fcltData.fcltTypeData.cameraId.value,
      };
      this.$http
        .get(
          `${
            this.$config.getServerConfig().ipes.api
          }/ipes/api/recentDetectData`,
          { params: param }
        )
        .then((result) => {
          this.detectData = result.data.docs;
          this.datacollection = {
            labels: [],
            datasets: [],
          };
          let tmpLabel = [];
          let tmpData = [];
          let tmpDataset = {
            label: '주간단속량',
            fill: false,
            data: [],
            backgroundColor: '#FFB300',
            borderColor: '#FFB300',
            borderWidth: 1,
          };
          for (var i = 0; i < 7; i++) {
            let dtLabel = moment()
              .hour(-i * 24)
              .format('YYYY-MM-DD');
            tmpLabel.push(dtLabel);
            tmpData.push(0);
          }

          this.detectData.forEach((d) => {
            let dtLabel = moment(d.firstDetectTime).format('YYYY-MM-DD');
            let index = _.findIndex(tmpLabel, (label) => {
              return label == dtLabel;
            });
            tmpData[index]++;
          });
          tmpDataset.data = tmpData;
          this.datacollection.labels = tmpLabel;
          this.datacollection.datasets[0] = tmpDataset;
          this.$forceUpdate();
        });
    },
  },
};
</script>
