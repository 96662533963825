var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("detect-dialog", {
        ref: "liveDetectDialog",
        attrs: { fcltData: _vm.fcltData }
      }),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "8px" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$refs.liveDetectDialog.showDialog()
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-play" }),
              _vm._v(" 실시간 단속 상태\n    ")
            ]
          )
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "dk-card" },
        [
          _c("bar-chart", {
            attrs: {
              "chart-data": _vm.datacollection,
              height: 340,
              options: _vm.chartOption
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dk-card-dark" }, [
      _c("div", { staticClass: "dk-card-title" }, [
        _vm._v("\n      주간 단속량\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }