import { render, staticRenderFns } from "./FCLTTYPE00001.vue?vue&type=template&id=7381f24f&"
import script from "./FCLTTYPE00001.vue?vue&type=script&lang=js&"
export * from "./FCLTTYPE00001.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7381f24f')) {
      api.createRecord('7381f24f', component.options)
    } else {
      api.reload('7381f24f', component.options)
    }
    module.hot.accept("./FCLTTYPE00001.vue?vue&type=template&id=7381f24f&", function () {
      api.rerender('7381f24f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/plugin/FCLTTYPE00001.vue"
export default component.exports